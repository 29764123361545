@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~leaflet/dist/leaflet.css";
@import '~react-leaflet-supercluster/src/styles.css';

html,
body,
#root {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 16px;
  background-color: #cacaca;
}

body::-webkit-scrollbar-thumb {
  background-color: #a8a8a9;
  border-radius: 4px;
}

div.react-datepicker-popper {
  z-index: 5;
}
.react-datepicker-wrapper {
  width: auto;
}

.react-weekpicker .react-datepicker__week:hover .react-datepicker__day,
.react-monthpicker
  .react-datepicker__month:hover
  .react-datepicker__day:not(.react-datepicker__day--outside-month) {
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  background: #5586aa;
}

.grecaptcha-badge {
  display: none;
}

.fc {
  --fc-button-bg-color: #306f9a;
  --fc-button-active-bg-color: #2c5069;
  --fc-button-hover-bg-color: #214d6b;

  --fc-button-border-color: #163d58;
  --fc-button-active-border-color: #163d58;
  --fc-button-hover-border-color: #163d58;
}

.fc.fc-theme-standard .fc-daygrid-day:hover {
  background-color: aliceblue;
  cursor: pointer;
}
.fc.fc-theme-standard .fc-toolbar-title {
  font-size: 1.2rem;
}

/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #68cbf8;
  color: #68cbf8;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #68cbf8;
  color: #68cbf8;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #68cbf8;
  color: #68cbf8;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
