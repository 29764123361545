/* Bariol */

@font-face {
  font-family: "Bariol";
  font-style: normal;
  font-weight: 400; /* normal */
  src: local("Bariol Regular"),
    url("./fonts/bariol/bariol_regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: italic;
  font-weight: 400; /* normal */
  src: local("Bariol Regular Italic"),
    url("./fonts/bariol/bariol_regular_italic-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: italic;
  font-weight: 100; /* thin */
  src: local("Bariol Thin Italic"),
    url("./fonts/bariol/bariol_thin_italic-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: normal;
  font-weight: 100; /* thin */
  src: local("Bariol Thin"),
    url("./fonts/bariol/bariol_thin-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: normal;
  font-weight: 200; /* light */
  src: local("Bariol Light"),
    url("./fonts/bariol/bariol_light-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: italic;
  font-weight: 200; /* light */
  src: local("Bariol Light Italic"),
    url("./fonts/bariol/bariol_light_italic-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: normal;
  font-weight: 700; /* bold */
  src: local("Bariol Bold"),
    url("./fonts/bariol/bariol_bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bariol";
  font-style: italic;
  font-weight: 700; /* bold */
  src: local("Bariol Bold Italic"),
    url("./fonts/bariol/bariol_bold_italic-webfont.woff") format("woff");
}

/* Lato */

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400; /* normal */
  src: local("Lato Regular"),
    url("./fonts/lato/Lato-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400; /* normal */
  src: local("Lato Regular Italic"),
    url("./fonts/lato/Lato-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  src: local("Lato Thin Italic"),
    url("./fonts/lato/Lato-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: local("Lato Thin"), url("./fonts/lato/Lato-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), url("./fonts/lato/Lato-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"),
    url("./fonts/lato/Lato-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), url("./fonts/lato/Lato-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"),
    url("./fonts/lato/Lato-BoldItalic.woff2") format("woff2");
}
